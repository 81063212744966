<template>
  <div class="catalog">
    <div class="catalog__container _container">
      <BreadCrumbs
        :items="
          this.$route.fullPath !== '/catalog'
            ? [[$t('main'), '/'], [$t('catalog'), '/catalog'], [categoryName]]
            : [
                [$t('main'), '/'],
                [$t('catalog'), '/catalog'],
              ]
        "
      />
      <h1 class="catalog__title main-title">{{ $t("catalog") }}</h1>
      <div class="catalog__body">
        <div class="catalog__sidebar catalog-sidebar">
          <div class="catalog-sidebar__block">
            <button
              class="catalog-sidebar__title active"
              @click="toast($event.target)"
            >
              {{ $t("categories") }}
            </button>
            <div class="catalog-sidebar__items" style="display: block">
              <div>
                <template v-for="(category, index) in categories" :key="index">
                  <div class="catalog-sidebar__item filt">
                    <div
                      class="catalog-sidebar__text"
                      @click="selectCategory(category)"
                    >
                      {{ category.title }}
                    </div>
                    <div
                      class="catalog-sidebar__items"
                      v-if="category.slug === showArr"
                    >
                    <template style="display: none;">
                      {{ mountCategory = category.id }}
                    </template>
                      <div
                        class="catalog-sidebar__item-elem"
                        v-for="(subCategory, idx) in category.sub_categories"
                        :key="idx"
                      >
                        <div
                          v-if="subCategory.slug === $route.params.slug"
                          style="display: none"
                        >
                          {{ (categoryName = subCategory.title) }}
                        </div>
                        <div v-else style="display: none">
                          {{ (categoryName = category.title) }}
                        </div>
                        <div class="catalog-sidebar__text">
                          {{ subCategory.title }}
                        </div>
                        <input
                          class="catalog-sidebar__checkbox"
                          type="checkbox"
                          :id="index"
                          :value="subCategory.id"
                          v-model="selectedCategory"
                        />
                      </div>
                    </div></div
                ></template>
              </div>
            </div>
          </div>
          <div class="catalog-sidebar__block">
            <button
              class="catalog-sidebar__title active"
              @click="toast($event.target)"
            >
              {{ $t("filters") }}
            </button>
            <div class="catalog-sidebar__items" style="display: block">
              <div class="catalog-sidebar__item filt">
                <div
                  class="catalog-sidebar__text textArrow"
                  :class="checkedBrands.length ? 'active' : ''"
                  @click="toast($event.target)"
                >
                  {{ $t("brands") }}
                </div>
                <div
                  class="catalog-sidebar__items"
                  :style="
                    checkedBrands.length ? 'display: block' : 'display: none'
                  "
                >
                  <div
                    v-for="(elem, index) in brands"
                    :key="index"
                    class="catalog-sidebar__item-elem"
                  >
                    <div class="catalog-sidebar__text">
                      {{ elem.title }}
                    </div>
                    <input
                      class="catalog-sidebar__checkbox"
                      type="checkbox"
                      :id="index"
                      :value="elem.id"
                      v-model="checkedBrands"
                      @click="getCheck"
                    />
                  </div>
                </div>
              </div>
              <div
                v-for="(item, index) in filters"
                class="catalog-sidebar__item filt"
                :key="index"
              >
                <div
                  class="catalog-sidebar__text textArrow"
                  @click="toast($event.target)"
                  v-if="item.items.length"
                >
                  {{ item.title }}
                </div>
                <div style="display: none">
                  <div
                    class="catalog-sidebar__item-elem"
                    v-for="(elem, idx) in item.items"
                    :key="idx"
                  >
                    <div class="catalog-sidebar__text">{{ elem.title }}</div>
                    <input
                      class="catalog-sidebar__checkbox"
                      type="checkbox"
                      :id="index"
                      :value="elem.id"
                      v-model="checkedNames"
                      @click="getCheck"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clearFilter" @click="clearFilter">{{ $t("reset") }}</div>
        </div>
        <div class="catalog__options">
          <button class="catalog__options-button" @click="showCatal">
            {{ $t("filter") }}
          </button>
          <button
            class="catalog__options-button"
            v-if="subsBek"
            style="background: white; border: 1px solid #1ABBE8; color: #1ABBE8"
            @click="showSubs"
          >
            {{ subsBek.title }}
          </button>
        </div>

        <div
          class="catalog__options__modal closeBlock"
          style="display: block"
          v-if="showCatalog"
        >
          <div
            @click="showCatal"
            style="
              display: flex;
              justify-content: flex-end;
              margin-bottom: 20px;
              color: #1ABBE8;
            "
          >
            Закрыть
          </div>
          <div class="catalog__options__modal__conts" style="display: block">
            <div class="catalog__options__modal__items">
              <div
                class="catalog__options__modal__title textArrow"
                @click="toast($event.target)"
              >
                {{ $t("brands") }}
              </div>
              <div
                class="catalog__options__modal__items"
                style="display: block"
              >
                <div
                  v-for="(elem, index) in brands"
                  :key="index"
                  class="catalog__options__modal__item-elem"
                >
                  <div class="catalog__options__modal__text">
                    {{ elem.title }}
                  </div>
                  <input
                    class="catalog__options__modal__checkbox"
                    type="checkbox"
                    :id="index"
                    :value="elem.id"
                    v-model="checkedBrands"
                  />
                </div>
              </div>
            </div>
            <div
              v-for="(item, index) in filters"
              class="catalog__options__modal__items"
              :key="index"
            >
              <div
                class="catalog__options__modal__title textArrow"
                @click="toast($event.target)"
              >
                {{ item.title }}
              </div>
              <div style="display: block">
                <div
                  class="catalog__options__modal__item-elem"
                  v-for="(elem, idx) in item.items"
                  :key="idx"
                >
                  <div class="catalog__options__modal__text">
                    {{ elem.title }}
                  </div>
                  <input
                    class="catalog__options__modal__checkbox"
                    type="checkbox"
                    :id="index"
                    :value="elem.id"
                    v-model="checkedNames"
                  />
                </div>
              </div>
            </div>
          </div>

          <button class="catalog__options__modal-button" @click="resultView">
            {{ $t("result") }}
          </button>
          <div class="clearFilter" @click="clearFilter">{{ $t("reset") }}</div>
        </div>

        <div
          class="catalog__options__modal closeBlock"
          style="display: block"
          v-if="showSubcat"
        >
          <div
            @click="showSubs"
            style="
              display: flex;
              justify-content: flex-end;
              margin-bottom: 20px;
              color: #1ABBE8;
            "
          >
            Закрыть
          </div>
          <div class="cMobile__content__block-subs">
            <div
              class="cMobile__content__block-elems"
              v-for="(subCategory, idx) in subsBek.sub_categories"
                      @click="selectCategory(subCategory), $router.push('/catalog/'+subCategory.slug)"
              :key="idx"
              style="margin-bottom: 20px; font-size: 16px;"
            >
              <div>{{ subCategory.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="catalog__content">
          <div v-if="loading" class="loading-indicator">
            Загрузка...
          </div>
          <div v-else>
            <div class="catalog__items">
              <div
                  class="catalog__item"
                  v-for="(product, index) in products"
                  :key="index"
              >
                <ProductItem
                    @change="update"
                    :product="product"
                    :currentPage="currentPage"
                    :category="selectedCategory"
                    :filters="checkedNames"
                    :brands="checkedBrands"
                ></ProductItem>
              </div>
            </div>
          </div>

          <div
            style="display: flex; justify-content: center"
            v-if="datas.last_page > 1"
          >
            <vue-awesome-paginate
              :total-items="datas.last_page * 5"
              :items-per-page="5"
              :max-pages-shown="3"
              v-model="currentPage"
              @click="onClickHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import "vue-awesome-paginate/dist/style.css";
import { mapActions, mapMutations, mapState } from "vuex";
import axios from "axios";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";
import clickOutSide from "@mahdikhashan/vue3-click-outside";

export default {
  components: {
    ProductItem,
    BreadCrumbs,
  },
  directives: {
    clickOutSide,
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      yelss: [],
      products: [],
      subsBek: null,
      datas: [],
      filters: [],
      brands: [],
      selectedFilters: [],
      selectedCategory: this.$route.query.idsub
        ? [this.$route.query.idsub]
        : [],
      selectedBrand: [],
      products1: [],
      checkedNames: [],
      categoryName: "",
      mainCategory: "",
      checkedBrands: [],
      showCatalog: false,
      showSubcat: false,
      showArr: null,
      mountCategory: null
    };
  },
  methods: {
    ...mapMutations(["SET_CATEGORY"]),
    ...mapActions(["fetchFavorites"]),
    onClickHandler() {
      this.filterProducts();
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    update() {
      this.fetchFavorites();
    },
    resultView() {
      this.showCatalog = false;
      document.body.classList.remove("hidde");
      this.showSubcat = false;
      document.body.classList.remove("hidde1");
    },
    showCatal() {
      this.showCatalog = !this.showCatalog;
      document.body.classList.toggle("hidde");
      window.scrollTo(0, 0);
    },
    showSubs() {
      this.showSubcat = !this.showSubcat;
      document.body.classList.toggle("hidde1");
      window.scrollTo(0, 0);
      this.getSubs()
    },
    toast(event) {
      event.classList.toggle("active");
      var panel = event.nextElementSibling;
      if (panel.style.display === "block" || panel.style.display === null) {
        panel.style.display = "none";
      } else {
        panel.style.display = "block";
      }
    },
    selectCategory(category) {
      this.showArr = category.slug;
      this.categoryName = category.title;
      this.SET_CATEGORY(category.id);
      this.$router.push("/catalog/" + category.slug);
    },
    clearFilter() {
      this.currentPage = 1;
      this.filterProducts();
      this.fetchFilters();
      this.selectedCategory = [];
      (this.checkedNames = []), (this.checkedBrands = []);
      var textArrow = document.querySelectorAll(".textArrow");
      for (const key in textArrow) {
        if (Object.hasOwnProperty.call(textArrow, key)) {
          textArrow[key].classList.remove("active");
          var panel = textArrow[key].nextElementSibling;
          if (panel.style.display === "block" || panel.style.display === null) {
            panel.style.display = "none";
          } else {
            panel.style.display = "block";
          }
        }
      }
    },
    fetchFilters() {
      let data = new FormData();
      data.append("lang", localStorage.getItem("lang") || "ru");
      if (this.selectedCategory.length) {
        for (let i = 0; i < this.selectedCategory.length; i++) {
          let file = this.selectedCategory[i];
          data.append("category_id[]", file);
        }
      } else if (this.category !== null) {
        data.append("category_id[]", this.category);
      } else {
        data.append("category_id[]", this.mountCategory);
      }
      axios
        .post(`V1/filters`, data)
        .then((response) => {
          this.filters = response.data.filters;
          this.brands = response.data.brands;
        })

        .catch((error) => {
        });
    },

    hideMenu() {
      this.showCatalog = false;
      this.showSubcat = false;
    },
    filterProducts() {
      let data = new FormData();
      data.append("lang", localStorage.getItem("lang") || "ru");
      if (this.$route.fullPath !== "/catalog") {
        if (this.selectedCategory.length) {
          for (let i = 0; i < this.selectedCategory.length; i++) {
            let file = this.selectedCategory[i];
            data.append("category_id[]", file);
          }
        } else if (this.category !== null) {
        data.append("category_id[]", this.category);
      } else {
        data.append("category_id[]", this.mountCategory);
      }
      }
      if (this.checkedNames.length) {
        for (let i = 0; i < this.checkedNames.length; i++) {
          let file = this.checkedNames[i];
          data.append("filter_id[]", file);
        }
      }
      if (this.checkedBrands.length) {
        for (let i = 0; i < this.checkedBrands.length; i++) {
          let file = this.checkedBrands[i];
          data.append("brand_id[]", file);
        }
      }

      axios
        .post(
          `V1/products?page=${this.currentPage}`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("access_token")
                ? `Bearer ${localStorage.getItem("access_token")}`
                : null,
            },
          }
          //     {
          //   params: {
          //
          //     lang: 'ru',
          //     category_id : this.selectedCategory.id,
          //     'filter_id[]' : JSON.stringify(this.selectedFilters.id)
          //
          //   },
          // }
        )
        .then((response) => {
          this.products = response.data.data.data;
          this.datas = response.data.data;
        })

        .catch((error) => {
        });
    },
    getSubs() {
      this.subsBek = false
      this.categories.forEach((item) => {
        item.id === this.sub ? this.subsBek = item : ''
      });
      if (!this.subsBek) {
        this.subsBek = false
      }
    },
    fetchProducts() {
      this.loading = true;
      let data = new FormData();
      data.append("lang", localStorage.getItem("lang") || "ru");
      if (this.selectedCategory.length) {
        for (let i = 0; i < this.selectedCategory.length; i++) {
          let file = this.selectedCategory[i];
          data.append("category_id[]", file);
        }
      } else if (this.category !== null) {
        data.append("category_id[]", this.category);
      } else {
        data.append("category_id[]", this.mountCategory);
      }
      if (this.checkedNames.length) {
        for (let i = 0; i < this.checkedNames.length; i++) {
          let file = this.checkedNames[i];
          data.append("filter_id[]", file);
        }
      }
      if (this.checkedBrands.length) {
        for (let i = 0; i < this.checkedBrands.length; i++) {
          let file = this.checkedBrands[i];
          data.append("brand_id[]", file);
        }
      }
      axios
        .post(`V1/products?page=${this.currentPage}`, data, {
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then((response) => {
          this.products = response.data.data.data;
          this.datas = response.data.data;
          this.loading = false;
        })

        .catch((error) => {
          this.loading = true;
        });
    },
    findSelected() {
      let selected = {};
      this.categories.forEach((category) => {
        selected = category.sub_categories.find((subCategory) => {
          parseInt(subCategory.id) === this.category;
        });
      });
    },
    // getCheck() {
    //   this.filterProducts();
    // },
  },
  computed: {
    ...mapState(["category", "sub", "categories", "brands"]),
  },
  async created() {

    if (
      parseInt(
        this.$router?.options?.history?.state?.forward?.split("page=")[1]
      ) > 0
    )
      this.currentPage = parseInt(
        this.$router.options.history.state.forward.split("page=")[1]
      );
    if (
      (await this.$router?.options?.history?.state?.forward?.split(
        "category="
      )[1]) !== undefined
    ) {
      this.selectedCategory = this.$router?.options?.history?.state?.forward
        ?.split("category=")[1]
        .split("&")[0]
        .split(",");
      this.checkedNames = this.$router?.options?.history?.state?.forward
        ?.split("filters=")[1]
        .split("&")[0]
        .split(",");
      this.checkedNames[0] == "" ? (this.checkedNames = []) : "";
      this.checkedBrands = this.$router?.options?.history?.state?.forward
        ?.split("brands=")[1]
        .split(",");
      this.checkedBrands[0] == "" ? (this.checkedBrands = []) : "";
    }
  },
  async mounted() {
    this.getSubs()
    this.fetchProducts();
    this.fetchFilters();
    this.findSelected();
    this.showArr = this.$route.params.slug;
  },
  watch: {
    mountCategory() {
      this.fetchProducts();
      this.fetchFilters();
      this.findSelected();
    },
    $route() {
      this.$route.fullPath === '/catalog' ? this.subsBek = false : ''
    },
    "$route.query.idsub"() {
      if (this.$route.query.idsub) {
        this.selectedCategory = [this.$route.query.idsub]
      }
    },
    "$route.params.slug": function () {
      this.selectedCategory = [];
      document.body.classList.remove("hidde1");
      (this.checkedNames = []), (this.checkedBrands = []);
      if (Object.keys(this.$route.query)[0]) {
        this.selectedCategory = [Object.keys(this.$route.query)[0]];
      }
      this.currentPage = 1;
      this.showArr = this.$route.params.slug;
      if (this.$route.query.idsub) {
        this.selectedCategory = [this.$route.query.idsub]
      }
      this.categories.forEach((item) => {
        const actName = item.sub_categories.find(
          (elem) => elem.slug === this.$route.params.slug
        );
        actName ? (this.categoryName = actName.title) : null;
      });
    this.hideMenu()
    this.getSubs()
    },
    checkedNames() {
      this.currentPage = 1;
      this.filterProducts();
    },
    checkedBrands() {
      this.currentPage = 1;
      this.filterProducts();
    },
    selectedCategory() {
      this.currentPage = 1;
      this.filterProducts();
      this.fetchFilters();
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-indicator {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.clearFilter {
  background: #ffffff;
  border: 1px solid #1ABBE8;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  margin-top: 10px;
  &:hover {
    background: #1ABBE8;
    border: 1px solid #ffffff;
    color: white;
    cursor: pointer;
  }
}
.catalog {
  padding: 50px 0 103px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 107px 0;
  }
  &__options {
    display: none;
    @media (max-width: $mobile + px) {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      &-button {
        padding: 10px;
        background: #1ABBE8;
        width: 48.5%;
        border-radius: 10px;
        color: white;
      }
    }
    &__modal {
      display: none;
      height: auto;
      position: fixed;
      bottom: 60px;
      left: 0;
      width: 100vw;
      background: white;
      z-index: 150;
      padding: 10px 20px;
      top: 70px;
      transition: top 2s;
      &__block {
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-subsParent {
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
        &-subs {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &:not(:last-child) {
          margin: 0 0 20px 0;
        }
      }

      &__conts {
        overflow-y: scroll;
        height: 40%;
      }
      &-button {
        margin-top: 10px;
        padding: 10px;
        background: #1ABBE8;
        width: 100%;
        border-radius: 10px;
        color: white;
      }
      &__title {
        font-weight: 600;
        text-align: left;
        font-size: 22px;
        line-height: 27px;
        margin: 10px 0;
        &:after {
          width: 16px;
          height: 27px;
          content: "";
          background: url("../assets/icons/bot.png") no-repeat center;
          color: #777;
          font-weight: bold;
          float: right;
          margin-left: 5px;
        }
        @media (max-width: $mobile + px) {
          font-weight: 500 !important;
          font-size: 14px !important;
        }
      }

      .active:after {
        background: url("../assets/icons/up.png") no-repeat center;
      }
      .filt {
        display: flex;
        flex-direction: column !important;
        .active:after {
          background: url("../assets/icons/minus.png") no-repeat center;
        }
      }
      &__item {
        display: flex;
        justify-content: space-between;
        &-elem {
          display: flex;
          padding: 10px 20px;
          justify-content: space-between;
        }
        &-border {
          border: none;
        }
        &:not(:last-child) {
          margin: 0 0 15px 0;
        }
      }
      @media (max-width: $mobile + px) {
        &__modal {
          display: block;
        }
      }
    }
  }
  &__body {
    @media (min-width: $tablet + px) {
      display: flex;
      align-items: flex-start;
      gap: 0 33px;
    }
  }

  &__content {
    flex: 0 1 100%;
  }

  &__title {
    margin: 0 0 53px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 22px 0;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 0 10px;

    @media (min-width: $tablet + px) {
      display: none;
    }
    @media (max-width: $tablet + px) {
      margin: 0 0 40px 0;
    }
  }

  &__btn {
    padding: 0 44px;
    @media (max-width: $mobileSmall + px) {
      flex: 0 1 calc(50% - 5px);
      padding: 0;
    }

    &:last-child {
      background: #ffffff;
      border: 1px solid #1ABBE8;
      color: #1ABBE8;
    }
  }

  &__sidebar {
    flex: 0 0 403px;
    background: #ffffff;
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    padding: 25px 22px 28px;
    @media (max-width: $tablet + px) {
      margin: 0 0 25px 0;
    }
    @media (max-width: $mobile + px) {
      display: none !important;
      margin: 0 0 25px 0;
    }
  }

  .accordion {
    &:not(:last-child) {
      margin: 0 0 25px 0;
    }
  }

  &__items {
    width: 100%;
    display: flex;
    gap: 2%;
    row-gap: 1vw;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (max-width: $tablet + px) {
      row-gap: 2vw;
    }
  }

  &__item {
    width: 32%;
    max-width: 32%;
    min-width: 32%;
    @media (max-width: $pc + px) {
    width: 49%;
    max-width: 49%;
    min-width: 49%;
    }
    @media (max-width: $tablet + px) {
      width: 32%;
      max-width: 32%;
      min-width: 32%;
    }
    @media (max-width: $mobile + px) {
    width: 49%;
    max-width: 49%;
    min-width: 49%;
    }
    @media (max-width: $mobileSmall + px) {
    width: 49%;
    max-width: 49%;
    min-width: 49%;
    }
  }

  .product-item {
    &__image {
      padding-bottom: 65%;
    }
  }

  &__filter {
    margin: 0 0 16.47px 0;
    text-align: right;
    @media (max-width: $tablet + px) {
      display: none;
    }

    .select {
      color: #000000;

      &__selected {
        height: 35px;
        padding: 0 10px 0 20px;
      }

      &__list {
        text-align: left;
      }

      &__title {
        display: flex;
        align-items: center;
        height: 80%;
        border-right: 1px solid #e3e3e3;
        padding: 0 13px 0 0;
        margin: 0 12px 0 0;
      }

      &__arrow {
        width: 8px;
        height: 8px;

        img {
          max-width: 100%;
          max-height: 100%;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.catalog-sidebar {
  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-subsParent {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    &-subs {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &:not(:last-child) {
      margin: 0 0 20px 0;
    }
  }

  &__title {
    font-weight: 600;
    text-align: left;
    font-size: 22px;
    line-height: 27px;
    margin: 0 0 10px 0;
    &:after {
      width: 16px;
      height: 27px;
      content: "";
      background: url("../assets/icons/bot.png") no-repeat center;
      color: #777;
      font-weight: bold;
      float: right;
      margin-left: 5px;
    }
  }

  .active:after {
    background: url("../assets/icons/up.png") no-repeat center;
  }
  .filt {
    display: flex;
    flex-direction: column !important;
    .active:after {
      background: url("../assets/icons/minus.png") no-repeat center;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    &-elem {
      display: flex;
      padding: 10px 20px;
      justify-content: space-between;
    }
    &-border {
      border: none;
    }
    &:not(:last-child) {
      margin: 0 0 15px 0;
    }
  }

  .filterIn {
    padding: 0 10px;
  }

  &__items {
    .accordion-button {
      &:not(.collapsed),
      &:focus {
        background: white;
        color: #202020;
        box-shadow: none;
        &::after {
          background-image: var(--bs-accordion-btn-icon);
        }
      }
    }
  }

  &__button {
    padding: 10px 0;
  }
  &__text {
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
    line-height: 22px;
    &:hover {
      color: #1ABBE8;
    }
  }

  .textArrow {
    cursor: pointer;
    &:after {
      width: 16px;
      height: 27px;
      content: "";
      background: url("../assets/icons/plus.png") no-repeat center;
      color: #777;
      font-weight: bold;
      float: right;
      margin-left: 5px;
    }
  }

  //
  //&__sub-items {
  //  padding: 20px 0 0 0;
  //}
  //
  //&__sub-list {
  //  padding: 21px 0 0 0;
  //}
  //
  //&__sub-item {
  //  &:not(:last-child) {
  //    margin: 0 0 17px 0;
  //  }
  //}
  //
  //&__sub-checkbox {
  //  padding: 0 0 0 15px;
  //
  //  &:not(:last-child) {
  //    margin: 0 0 15px 0;
  //  }
  //}
  //
  //&__title, &__sub-title {
  //  width: 100%;
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-between;
  //  gap: 0 12px;
  //}
  //
  //&__title {
  //  font-weight: 600;
  //  font-size: 22px;
  //  line-height: 27px;
  //}
  //
  //&__sub-title {
  //  @extend .base-text-500;
  //}

  &__checkbox {
    width: 20px;
    height: 20px;
    background: #1ABBE8;
    border: 1px solid #1ABBE8;
    border-radius: 2px;
    accent-color: #1ABBE8;
  }

  //&__icon {
  //  width: 24px;
  //  height: 24px;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //
  //  img {
  //    width: 10.54px;
  //    height: 6.25px;
  //  }
  //}
}
</style>